import axios from 'axios'

export const getTestimonials = async () => {
  const res = await axios.get('/api/testimonials/')
  return res.data
}

export const addTestimonialsToDom = (data) => {
  const testimonials = document.getElementById('testimonials')
  const content = testimonials.querySelector('.content')
  data.testimonials?.forEach((e) => {
    const testimonial = document.createElement('div')
    testimonial.classList.add('testimonial')
    const text = document.createTextNode(e.text)
    const p = document.createElement('p')
    const name = document.createTextNode(e.name)
    testimonial.appendChild(text)
    p.appendChild(name)
    testimonial.appendChild(p)
    content.appendChild(testimonial)
  })
}

import axios from 'axios'
import { getShows, addShowsToDom } from './shows'
import { getTestimonials, addTestimonialsToDom } from './testimonials'

getTestimonials().then((data) => {
  addTestimonialsToDom(data)
})

getShows().then((data) => {
  addShowsToDom(data)
})
// load().then((res) => {
//   console.log('res.data', res.data)
// })

document.querySelector('body').addEventListener('click', (event) => {
  if (
    !event.target.classList.contains('hamburger') &&
    event.target.id !== 'links'
  ) {
    document.getElementById('links').classList.remove('show')
  }
})

const chevrons = document.querySelectorAll('.icon-down')
chevrons.forEach((e) =>
  e.addEventListener('click', (event) => {
    const target = event.target?.id
    if (target !== null && target !== undefined) {
      const id = target.split('to-')[1]
      document.getElementById(id)?.scrollIntoView()
    }
  })
)

document.querySelector('.hamburger').addEventListener('click', () => {
  document.getElementById('links').classList.toggle('show')
})

document.querySelectorAll('.navigation-link').forEach((link) => {
  link.addEventListener('click', () => {
    setTimeout(() => {
      document.getElementById('links').classList.remove('show')
    }, 250)
  })
})

const checkRequired = (value) => {
  return value.length > 0 || 'This field is required.'
}

const checkEmail = (value) => {
  const regex = new RegExp('[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]+')
  return regex.test(value) || 'Please enter a valid email.'
}

const checkPhone = (value) => {
  const regex = new RegExp(
    '^[+]?[(]?[0-9]{3}[)]?[-\\s.]?[0-9]{3}[-\\s.]?[0-9]{4,6}$'
  )
  return regex.test(value) || 'Please enter a valid phone number.'
}

const checkValidation = (item, element) => {
  const errors = item.validation
    .filter((f) => f(element.value) !== true)
    .map((f) => f(element.value))
  const small = document.getElementById(`${item.id}-errors`)
  const span = small.querySelector('span')
  if (errors.length > 0) {
    span.innerHTML = errors.join(' ')
    small.classList.add('error-show')
  } else {
    span.innerHTML = ''
    small.classList.remove('error-show')
  }
  return errors.length
}

const formItems = [
  {
    id: 'name',
    validation: [checkRequired],
  },
  {
    id: 'email',
    validation: [checkRequired, checkEmail],
  },
  {
    id: 'phone',
    validation: [checkRequired, checkPhone],
  },
  {
    id: 'message',
    validation: [checkRequired],
  },
]

formItems.forEach((item) => {
  const element = document.getElementById(item.id)
  element.addEventListener('blur', () => {
    checkValidation(item, element)
  })
})

const contactButton = document.getElementById('contact-button')
contactButton.addEventListener('click', async (e) => {
  e.preventDefault()
  let errorCount = 0
  const body = {}
  formItems.forEach((item) => {
    const element = document.getElementById(item.id)
    errorCount += checkValidation(item, element)
    body[item.id] = element.value
  })

  if (errorCount === 0) {
    contactButton.disabled = true
    contactButton.innerHTML = 'Sending...'
    try {
      await axios.post('/api/send-email/', body)
      document.getElementById('contact-form').reset()
      document
        .getElementById('success-message')
        .style.setProperty('display', 'flex')
    } catch (err) {
      console.log('err', err)
    } finally {
      contactButton.disabled = false
      contactButton.innerHTML = 'Submit'
    }
  } else {
    console.log('bad')
  }
})

document
  .getElementById('success-message-close-button')
  .addEventListener('click', () => {
    document
      .getElementById('success-message')
      .style.setProperty('display', 'none')
  })

import axios from 'axios'

export const getShows = async () => {
  const res = await axios.get('/api/shows/')
  return res.data
}

const createUrl = (data: {
  city: any
  state: any
  isPrivate: any
  location: any
  address: any
}) => {
  const esc = encodeURIComponent
  let details = `${data.city},${data.state}`
  if (!data.isPrivate) {
    details = `${data.location} ${data.address} ${details}`
  }
  return `https://www.google.com/search?q=${esc(details)}`
}

const formatShow = (data, url = true) => {
  let show
  if (url) {
    show = document.createElement('a')
    show.setAttribute('href', createUrl(data))
    show.setAttribute('target', '_blank')
    show.classList.add('hover')
  } else {
    show = document.createElement('div')
  }
  show.classList.add('show')
  const geographical = document.createElement('div')
  geographical.classList.add('where')
  const temporal = document.createElement('div')
  temporal.classList.add('when')
  const dateInfo = data.date
  const date = document.createElement('div')
  date.appendChild(document.createTextNode(`Date: ${dateInfo}`))
  date.classList.add('date')
  const timeInfo = data.time
  const time = document.createElement('div')
  time.appendChild(document.createTextNode(`Time: ${timeInfo}`))
  time.classList.add('time')
  const addressInfo = document.createTextNode(
    `${data.address} ${data.city}, ${data.state}`
  )
  const address = document.createElement('div')
  address.appendChild(addressInfo)
  address.classList.add('address')
  const location = document.createElement('div')
  location.appendChild(document.createTextNode(data.location))
  location.classList.add('location')
  geographical.appendChild(location)
  geographical.appendChild(address)
  temporal.appendChild(date)
  temporal.appendChild(time)
  show.appendChild(geographical)
  show.appendChild(temporal)
  return show
}

export const addShowsToDom = (data) => {
  const upcoming = document.getElementById('upcoming')
  const past = document.getElementById('past')
  data.shows?.upcoming?.forEach((e) => {
    upcoming.appendChild(formatShow(e))
  })
  data.shows?.past?.forEach((e) => {
    past.appendChild(formatShow(e, false))
  })
}
